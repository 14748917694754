import React from "react"
import TailoringForm from "../components/form"
import { graphql } from "gatsby"
import Layout from "../components/layout"

const Contact = ({ data, location }) => {
  const info = data.allContentfulWeb.nodes[0].info
  return (
    <Layout location={location}>
      <div className="container">
        <div className="page-header page-header-big text-center"
             style={{ backgroundImage: "url('/images/contact-header-bg.jpg')" }}>
          <h1 className="page-title text-white">Liên hệ với chúng tôi</h1>
        </div>
      </div>

      <div className="page-content pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-2 mb-lg-0">
              <h2 className="title mb-2 text-center">Thông tin liên hệ</h2>
              <div className="row">

                <div className="contact-info">
                  <ul className="contact-list">
                    <li>
                      Mã số thuế: {info.tax_id}
                    </li>
                    <li>
                      Địa chỉ: {info.address}
                    </li>
                    <li>
                      Số điện thoại: <a href={`tel:${info.phone}`}>{info.phone}</a>
                    </li>
                    <li>
                      Email: <a href={`mailto:${info.mail}`}>{info.mail}</a>
                    </li>
                    <li>
                      Fanpage: <a
                      href="https://www.facebook.com/May-M%E1%BA%B7c-Ng%C3%B4-H%C6%B0%E1%BB%9Dng-111256920469597/">May
                      mặc Ngô Hường</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <TailoringForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Contact

export const pageQuery = graphql`
  query {
    allContentfulWeb {
      nodes {
        info {
          address
          tax_id
          phone
          mail
        }
      }
    }
  }
`